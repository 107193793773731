<style lang="scss" scoped>
	@import "./import-products.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">

                </div>
            </div>
        </div>
        <div slot="body" class="container-fluid mb-2" >
            <div class="row">
                <vue-dropzone ref="myVueDropzoneFile" id="dropzoneFile" class="dropzone" :useCustomSlot="true" :options="dropzoneOptions" v-on:vdropzone-success="successResponseFile" v-on:vdropzone-sending="sendingEvent" >
                    <div class="dropzone-custom-content">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <h5 class="dropzone-custom-title">Solte o arquivo para importar</h5>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </cc-modal>
</template>


<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import * as CONSTANTS from "@/constants/constants";

export default {
    components: { vueDropzone: vue2Dropzone },
    props: {
		client: {
			type: Object,
			required: true
		}
	},
    data() {
		return {
			modal: {
				title: `importar produtos`,
				subtitle: `Aqui você importa os produtos do cliente`,
				icon_title: 'fas fa-upload',
				cancel_text: 'Voltar',
				style: {
					width: "70%",
					height: "fit-content"
				}
			},
            dropzoneOptions: {
                url: `${CONSTANTS.API_V3_URL}/admin/clients/${this.client.id}/products/import`,
                timeout: 600000,
                paramName: "file",
                withCredentials: false,
                forceFallback:false,
                headers: this.getHeaders()
            },
		};
	},
    methods: {
        close() {
            this.$emit("close");
		},
        getHeaders(){
            return {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Cache-Control': null
            }
        }
    }
}

</script>
