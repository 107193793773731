<template>
  <div class="modal-mask">
    <div class="modal-container">
      <div class="delete-product-header-container">
        <span class="delete-product-header-title">
          Titulo
        </span>
        <span @click="close" class="material-icons closebtn-icon">
          cancel
        </span>
      </div>
      <form class="delete-product-body" @submit.prevent="handleClick">
        <h1 class="delete-product-title">ATENÇÃO!</h1>
        <h2 class="delete-product-title">Essa ação é irreversível e ira excluir todos os produtos do cliente.</h2>
        <input type="password" v-model="password" placeholder="Senha" class="delete-product-password">
        <Button text="Excluir produtos" :action="handleClick" class="delete-product-button" />
      </form>

    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/buttons/StandardButton.vue'

export default {
  name: 'deleteProducts',
  props: {
    close: {
      type: Function
    },
    deleteProducts: {
      type: Function
    }
  },
  components: {
    Button
  },
  data() {
    return {
      password: ''
    }
  },
  methods: {
    handleClick() {
      if (this.password == '256025') this.deleteProducts()
      else this.$swal.fire(
        "Erro!",
        "Desculpe, senha incorreta",
        "error"
      );
    }
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 50vw;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px 10px 10px 10px;
}

.delete-product-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}

.delete-product-header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}

.delete-product-body {
  padding: 5% 3%;
}

.delete-product-title {
  color: #605F5F;
  text-align: center;
}

.delete-product-password {
  width: 40%;
  margin: 0 auto;
  margin-top: 5%;
  border-radius: 8px;
  border: 1px solid gray;
  padding: 1.5% 3%;
}

.delete-product-button {
  width: 35%;
  margin: 0 auto;
  margin-top: 5%;
}
</style>