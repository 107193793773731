<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="manage-categories__main-container">
                <div class="manage-categories__header-container">
                    <span class="manage-categories__header-title">
                        Gerenciar Categorias de Produtos
                    </span>
                    <span @click="closeDialog" class="material-icons manage-categories__closebtn-icon">
                        cancel
                    </span>
                </div>
                <div class="manage-categories__title-wrapper">
                    <span class="manage-categories__title">Minhas Categorias</span>
                    <StandardButton class="standard-btn" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'" />
                </div>
                <!-- <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="standard-inputs__container">
                        <ValidationProvider rules="required" v-slot="v">
                            <div class="standard-inputs__wrapper">
                                <div class="manage-categories__input-wrapper">
                                    <span class="manage-categories__input-title">Código Interno</span>
                                    <input :placeholder="'Digite o Código Interno'" v-model="new_category.cat_codInterno"
                                        class="manage-categories__input">
                                </div>
                            </div>
                            <span style="margin: 2em;" class="error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider rules="required" v-slot="v" v-if="!isReadyOnly">
                            <div class="standard-inputs__wrapper">
                                <div class="manage-categories__input-wrapper">
                                    <span class="manage-categories__input-title">Nova Categoria</span>
                                    <div class="manage-categories__new-category__wrapper">
                                        <input :placeholder="'Adicione uma Nova Categoria'"
                                            v-model="new_category.cat_descricao" @search="handleSubmit(create)"
                                            class="manage-categories__new-category-input">
                                        <span @click="handleSubmit(create)"
                                            class="material-icons-outlined manage-categories__input-icon plus-icon">add</span>
                                    </div>
                                </div>
                            </div>
                            <span style="margin: 2em;" class="error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </ValidationObserver> -->
                <div class="categories-filter-container">
                    <div>
                        <span class="manage-categories__input-title">Código Interno</span>
                        <input v-model="new_category.cat_codInterno" :placeholder="'Digite o Código Interno'"
                            class="manage-categories__chosen-category-input" style="cursor: text;">
                    </div>
                    <div style="width: 25%;">
                        <span class="manage-categories__input-title">Nova Categoria</span>
                        <input v-model="new_category.cat_descricao" :placeholder="'Adicione uma Nova Categoria'"
                            class="manage-categories__chosen-category-input" style="cursor: text; width: 100%;">
                    </div>
                    <div style="width: 25%;">
                        <span class="manage-categories__input-title">Categoria padrão</span>
                        <StandardInput :placeholder="'Selecione a categoria padrão'" :list="categoriesOptions"
                         :action="value => new_category.cat_id = value.cat_id" type="select" style="width: 100%;"/>
                    </div>
                    <StandardButton style="margin-left: auto;"  :action="create" :iconName="'save'" :text="'Adicionar'" />
                </div>
                <cc-loader v-if="loading" style="margin-top: 10vh;" />
                <div v-else class="overflow-container">
                    <div class="modal-table-container">
                        <div class="modal-table-header">
                            <div class="modal-table-colum id-width">ID</div>
                            <div class="modal-table-colum cod-width">Código Interno</div>
                            <div class="modal-table-colum name-width">Nome</div>
                            <div class="modal-table-colum cat-width">Categoria Padrão</div>
                            <div class="modal-table-colum action-width text-center">Ação</div>
                        </div>
                        <div v-for="(cat, idx) in categories" :key="cat.id"
                            :class="{ selected: cat.selected, 'page-table-line-special': idx % 2 != 0 }">
                            <div class="modal-table-row">
                                <div class="modal-table-colum id-width">
                                    <div class="manage-categories-mobile-header">ID</div>
                                    <b>#{{ cat.cat_id }}</b>
                                </div>
                                <div class="modal-table-colum cod-width">
                                    <div class="manage-categories-mobile-header">Código Interno</div>
                                    {{ cat.cat_codInterno }}
                                </div>
                                <div v-if="!cat.edit" class="modal-table-colum name-width">
                                    <div class="manage-categories-mobile-header">Nome</div>
                                    <input v-model="cat.cat_descricao" :readonly="true"
                                        class="manage-categories__chosen-category-input">
                                </div>
                                <div v-else class="modal-table-colum name-width">
                                    <div class="manage-categories-mobile-header">Nome</div>
                                    <input v-model="cat.cat_descricao" :readonly="false"
                                        class="manage-categories__chosen-category-input input-selected">
                                </div>
                                <div class="modal-table-colum cat-width">
                                    <div class="manage-categories-mobile-header">Categoria Padrão</div>
                                    <StandardInput
                                        :class="cat.edit ? 'input-selected' : ''"
                                        :placeholder="cat.defaultCategorie"
                                        :action="value => cat.cat_cat_id_padrao = value.cat_codInterno"
                                        :list="categoriesOptions"
                                        :type="cat.edit ? 'select' : 'disabled'"/>
                                </div>
                                <div class="modal-table-colum action-width">
                                    <div class="manage-categories-mobile-header">Ação</div>
                                    <div class="text-center">
                                        <span v-if="!cat.edit && !cat.loading && !isReadyOnly" @click="select(cat)"
                                            class="material-icons-outlined edit-btn">edit</span>
                                        <span v-if="cat.edit && !cat.loading && !isReadyOnly" @click="update(cat)"
                                            class="material-icons-outlined save-btn">save</span>
                                        <span v-show="!cat.loading && !isReadyOnly" @click.stop="remove(cat)"
                                            class="material-icons-outlined delete-btn">delete</span>
                                        <cc-loader style="left: -15vw;" v-show="cat.loading" :show_txt="false"
                                            class="loader float-right" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <tr v-show="total_items > items_by_page_value" class="seemore-button-containear">
                        <td class="text-center" colspan="6">
                            <Paginacao classes="orange justify-end" :items_by_page_value="items_by_page_value"
                                :total="total_items" :page="page" v-on:change="update_page($event)">
                            </Paginacao>
                        </td>
                    </tr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryService from "@/services/v1/category.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import * as CONSTANTS from "@/constants/constants";
import StandardButton from "@/components/ui/buttons/StandardButton.vue";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import Paginacao from "@/components/cliente/base-components/Pagination";

export default {
    mixins: [loaderMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        StandardButton,
        Paginacao,
        StandardInput
    },
    props: ["closeDialog", 'isReadyOnly', 'id'],
    data() {
        return {
            page: 1,
            items_by_page_value: 30,
            total_items: 1000,
            categories: [],
            category: {},
            new_category: {},
            page: 1,
            filters: {
                name: '',
                total_items: 0,
                per_page: 0
            },
            categoriesOptions: [],
            loading: false,
            service: new CategoryService(),
            modal: {
                title: `gerenciar categorias de produtos`,
                subtitle: `Gerencie categorias de seus produtos para melhor organização de seu catálogo`,
                icon_title: 'fas fa-tags',
                cancel_text: 'Voltar',
                style: {
                    width: "70%"
                }
            },
        };
    },
    methods: {
        update_page(page) {
            this.page = page;
            load_categories()
        },
        select(cat) {
            this.categories.forEach(c => c.edit = false)
            cat.edit = true
            this.$forceUpdate()
        },
        update(cat) {
            this.loading = true


            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

            }
            const body = {
                cat_cat_id_padrao: cat.cat_cat_id_padrao,
                cat_descricao: cat.cat_descricao,
            }
            let url = `${CONSTANTS.API_V3_URL}/admin/clients/${this.id}/categories/${cat.cat_id}`
            axios.put(url, body, config)
                .then(() => { this.load_categories(); })
        },
        create() {
            this.loading = true


            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
            const body = {
                "cli_id": this.id,
                "for_id": null,
                "cat_codInterno": this.new_category.cat_codInterno,
                "cat_descricao": this.new_category.cat_descricao,
                "cat_status": "1",
                "cat_excluido": "0",
                "cat_idtemp": null,
                "cat_cat_id_padrao": this.new_category.cat_id
            }
            let url = `${CONSTANTS.API_V3_URL}/admin/clients/${this.id}/categories`
            axios.post(url, body, config)
                .then(() => { this.load_categories(); })
        },
        remove(cat) {
            this.confirm_action({
                message: "Confirma remoção da categoria?",
                subtitle: `Categoria #${cat.cat_descricao} será removida!`,
                callback: () => {
                    const config = {
                      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                    }
                    axios.delete(`${CONSTANTS.API_V3_URL}/admin/management/categories/${cat.cat_id}`, config)
                      .then(data => {
                        this.load_categories()
                      }).catch(error => {
                      ErrorHandlerService.handle(error, this.$store);
                    })
                }
            })
        },
        close() {
            this.$emit("close");
        },
        loadSelect() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }
            axios.get(`${CONSTANTS.API_V3_URL}/admin/management/categories`, config)
                .then(data => {
                    data.data.forEach(element => {
                        this.categoriesOptions.push({text: element.cat_descricao, value: element})
                    });
                    this.load_categories()
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                })
        },
        load_categories() {
            this.loading = true
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }
            axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/${this.id}/categories`, config)
                .then(data => {
                    this.categories = []
                    data.data.forEach(element => {
                        element.defaultCategorie = this.getDefaultCategorie(element.cat_cat_id_padrao)
                        this.categories.push(element)
                    });
                    this.total_items = data.data
                    this.loading = false
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                })
        },
        getDefaultCategorie (id) {
            let resp
            this.categoriesOptions.forEach(element => {
                if (element.value.cat_codInterno == id) resp = element.text
            })
            return resp
        }
    },
    mounted() {
        this.loadSelect()
    }
};
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    min-width: 50vw;
    border-radius: 12px;
}

.manage-categories__main-container {
    width: 90vw;
    height: 110vh;
}
.manage-categories__chosen-category-input:focus{
    outline: none;
}

.manage-categories__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.manage-categories__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.manage-categories__closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.manage-categories__title-wrapper {
    margin: 0em 0em 0em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manage-categories__title {
    color: #707070;
    font-weight: 400;
    font-size: 2.2em;
}

.standard-inputs__container {
    display: flex;
    align-items: center;
}

.standard-inputs__wrapper {
    display: flex;
    align-items: center;
    margin: 0em 1.2em;
}

.manage-categories__input-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}

.manage-categories__input-title {
    color: #898989;
    font-weight: 400;
    font-size: 1.8em;
    letter-spacing: 0.15px;
    margin-bottom: 0.3em;
}

.manage-categories-mobile-header {
    display: none;
}

.manage-categories__new-category__wrapper {
    display: flex;
    align-items: center;
}

.manage-categories__input {
    width: 30vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}

.manage-categories__chosen-category-input {
    padding: 1.3vh 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #B3B3B3;
    opacity: 0.7;
    cursor: not-allowed;
}


.categories-filter-container{
    display: flex;
    align-items: flex-end;
    margin: 0 2em;
    gap: 3em;
}

.id-width{
    width: 8%;
}
.cod-width{
    width: 15%;
}
.name-width{
    flex: 1;
}
.cat-width{
    width: 25%;
}
.action-width{
    width: 15%;
}

.manage-categories__new-category-input {
    width: 30vw;
    height: 6vh;
    padding: 0px 10px;
    font-weight: 400;
    font-size: 1.1em;
    border-top: 2px solid #c2c2c3;
    border-left: 2px solid #c2c2c3;
    border-bottom: 2px solid #c2c2c3;
    border-right: none;
    border-radius: 5.23px 0px 0px 5.23px;
    color: #A1A1A1;
}

.manage-categories__input-icon {
    position: relative;
    width: 4vw;
    height: 6vh;
    left: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 0px 5px 5px 0px;
    border-top: 1px solid #c2c2c3;
    border-bottom: 1px solid #c2c2c3;
    border-right: 1px solid #c2c2c3;
    cursor: pointer;
}

.standard-btn {
    width: 10vw;
    margin: 2em;
}

.overflow-container {
    overflow-x: auto;
    max-height: 55vh;
}

.modal-table-container {
    min-height: 40vh;
    margin: 2em;
}

.modal-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 17px;
    color: #605F5F;
    margin-bottom: 1vh;
    align-items: center;
}

.modal-table-colum {
    padding-left: 0.5vw;
}

.modal-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    margin-bottom: 1vh;
    align-items: center;
    padding: 0.5vh 0;
}

.edit-btn {
    margin-top: 0.2em;
    cursor: pointer;
    font-size: 1.8em;
}

.save-btn {
    color: var(--primary-color);
    margin-top: 0.2em;
    cursor: pointer;
    font-size: 1.8em;
}

.delete-btn {
    color: #CD2A2A;
    margin-left: 0.3em;
    cursor: pointer;
    font-size: 1.8em;
}

.input-selected {
    cursor: pointer;
    opacity: 1;
    outline: 1px solid #ff7110 !important;
    border-radius: 5.5px;
}

.seemore-button-containear {
    display: flex;
    margin: 0 auto;
    overflow-x: auto;
    max-width: 50vw;
}

.page-table-line-special {
    background: #F7F7F7;
}


@media only screen and (max-width: 800px) {
    .modal-mask {
        font-size: 2vw;
    }

    .standard-btn {
        margin: 1em auto;
        width: 65vw;
        margin-right: 2vw;
    }

    .manage-categories__title-wrapper {
        margin: 0.5 auto;
    }

    .standard-inputs__container {
        flex-direction: column;
    }

    .manage-categories__input {
        margin: 1em auto;
        width: 65vw;
    }

    .manage-categories__new-category-input {
        margin: 1em 0em;
        width: 65vw;
    }

    .manage-categories__input-icon {
        position: relative;
        height: 6vh;
        left: -15px;
        width: 6vw;
    }

    .overflow-container {
        max-height: 25vh;
    }

    .delete-btn {
        margin-left: 0em;
    }

    .modal-table-header {
        display: none
    }

    .manage-categories__main-container {
        width: 110vw;
    }

    .modal-table-row {
        flex-direction: column;
        gap: 1vh;
        font-size: 1.4em;
        margin-bottom: 3vh;
    }

    .modal-table-colum {
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between
    }

    .manage-categories-mobile-header {
        display: flex;
        font-size: 1.2em;
        font-weight: 700
    }

    .manage-categories__chosen-category-input {
        width: 30vw
    }
}

@media only screen and (max-width: 500px) {
    .modal-mask {
        font-size: 3vw;
    }

    .manage-categories__chosen-category-input {
        width: 45vw
    }
}

/* @import "./manage-categories.modal"; */
</style>
